
import { defineComponent, ref, reactive, watch, nextTick, onMounted } from 'vue';
import {
  getModelYear,
  getRegion,
  getComDealerList,
  getComProgramList,
  getCheckingRoundList,
  getTypeClass,
  getDealerGroup
} from '@/API/salesReporting/claimCheckingReport';
import { message, TreeSelect } from 'ant-design-vue';
import downloadFile from '@/utils/claim/downloadFile';
import moment from 'moment';
import { useStore } from 'vuex';
import useFetch from '@/hooks/useFetch';
import { getDealerByBUID, getDealerByGroupId, getIncentiveAll } from '@/API/salesReporting';
import { useRoute } from 'vue-router';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import * as echarts from 'echarts';
import { PageOptionProps } from '@/views/User/types';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const tableWidth = window.innerWidth;
const distinctColor = (column: any, type: string) => {
  return { class: [type] };
};
const dealerCol = [
  { title: 'Dealer Code(Cofico)', dataIndex: 'dealerCodeCofico', width: 140, fixed: 'left' },
  { title: 'Dealer Name(CN)', dataIndex: 'dealerNameCn', width: 280, fixed: 'left' },
  { title: 'Dealer Code (SWT)', dataIndex: 'dealerCodeSwt', width: 130 },
  { title: 'Dealer Name(EN)', dataIndex: 'dealerNameEn', width: 280 },
  {
    title: 'Dealer Group(CN)',
    dataIndex: 'dealerGroupNameCn',
    width: 130
  },
  {
    title: 'Dealer Group(EN)',
    dataIndex: 'dealerGroupNameEn',
    width: 130
  },
  { title: 'Sub Region', dataIndex: 'subRegion', width: 110 },
  { title: 'Business Unit', dataIndex: 'bu', width: 130 },
  {
    title: 'Program Category',
    dataIndex: 'programCategoryNameEn',
    width: 150
  },
  { title: 'Year', dataIndex: 'year', width: 100 },
  { title: 'Quarter', dataIndex: 'quarter', width: 100 },
  { title: 'Month', dataIndex: 'month', width: 100 },
  { title: 'Program No.', dataIndex: 'programNo', width: 120 },
  { title: 'Submited Claim No.', dataIndex: 'submitedClaimNo', width: 130 },
  {
    title: 'Estimated Claim Amount',
    dataIndex: 'estimatedClaimAmount',
    width: 180
  },
  {
    title: 'OEM Approved Claim Segment',
    customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved'),
    children: [
      {
        title: 'OEM Approved Claim No.',
        dataIndex: 'oemApprovedClaimNo',
        width: 200,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'OEM Approved Claim Amount',
        dataIndex: 'oemApprovedClaimAmount',
        width: 230,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Confirmed Approval Claim No.',
        dataIndex: 'dealerConfirmedApprovalClaimNo',
        width: 270,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Confirmed Approval Claim Amount',
        dataIndex: 'dealerConfirmedApprovedClaimAmount',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Confirmed Approved Claim Rate',
        dataIndex: 'dealerConfirmedApprovedClaimRate',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Rejected Approved Claim No.',
        dataIndex: 'dealerRejectedApprovedClaimNo',
        width: 270,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Rejected Approved Claim Amount',
        dataIndex: 'dealerRejectedApprovedClaimAmount',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Rejected Approved Claim Rate',
        dataIndex: 'dealerRejectedApprovedClaimRate',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Appealed Approved Claim No.',
        dataIndex: 'dealerAppealedApprovedClaimNo',
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Appealed Approved Claim Amount',
        dataIndex: 'dealerAppealedApprovedClaimAmount', //TODO 未返回
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Appealed Approved Claim Rate',
        dataIndex: 'dealerAppealedApprovedClaimRate',
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Un-confirmed Approved Claim No.',
        dataIndex: 'dealerUnConfirmedApprovedClaimNo',
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Un-confirmed Approved Claim Amount',
        dataIndex: 'dealerUnConfirmedApprovedClaimAmount',
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      },
      {
        title: 'Dealer Un-confirmed Approved Claim Rate',
        dataIndex: 'dealerUnConfirmedApprovedClaimRate',
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-approved')
      }
    ]
  },
  {
    title: 'OEM Rejected Claim Segment',
    customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected'),
    children: [
      {
        title: 'OEM Rejected Claim No.',
        dataIndex: 'oemRejectedClaimNo',
        width: 200,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'OEM Rejected Claim Amount',
        dataIndex: 'oemRejectedClaimAmount',
        width: 250,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Confirmed Rejected Claim No.',
        dataIndex: 'dealerConfirmedRejectedClaimNo',
        width: 270,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Confirmed Rejected Claim Amount',
        dataIndex: 'dealerConfirmedRejectedClaimAmount',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Confirmed Rejected Claim Rate',
        dataIndex: 'dealerConfirmedRejectedClaimRate',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Rejected Failed Claim No.',
        dataIndex: 'dealerRejectedFailedClaimNo',
        width: 270,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Rejected Failed Claim Amount',
        dataIndex: 'dealerRejectedFailedClaimAmount',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Rejected Failed Claim Rate',
        dataIndex: 'dealerRejectedFailedClaimRate',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Appealed Rejected Claim No.',
        dataIndex: 'dealerAppealedRejectedClaimNo',
        // dataIndex: 'dealerAppealedFailedClaimNo',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Appealed Rejected Claim Amount',
        dataIndex: 'dealerAppealedRejectedClaimAmount',
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Appealed Rejected Claim Rate',
        dataIndex: 'dealerAppealedRejectedClaimRate',
        // dataIndex: 'dealerAppealedRejectedFailedClaimRate',
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Un-confirmed Rejected Claim No.',
        dataIndex: 'dealerUnConfirmedRejectedClaimNo',
        width: 300,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Un-confirmed Rejected Claim Amount',
        dataIndex: 'dealerUnConfirmedRejectedClaimAmount',
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      },
      {
        title: 'Dealer Un-confirmed Rejected Claim Rate',
        dataIndex: 'dealerUnConfirmedRejectedClaimRate',
        width: 330,
        customHeaderCell: (column: any) => distinctColor(column, 'distinct-rejected')
      }
    ]
  },
  {
    title: 'OEM Pending Claim No.',
    dataIndex: 'oemPendingClaimNo',
    width: 200,
    customHeaderCell: (column: any) => distinctColor(column, 'distinct-pending')
  },
  {
    title: 'OEM Pending Claim Amount',
    dataIndex: 'oemPendingClaimAmount',
    width: 200,
    customHeaderCell: (column: any) => distinctColor(column, 'distinct-pending')
  },
  {
    title: 'OEM Un-Checked Claim No.',
    dataIndex: 'oemUnCheckedClaimNo',
    width: 200,
    customHeaderCell: (column: any) => distinctColor(column, 'distinct-unchecked')
  },
  {
    title: 'OEM Un-Checked Claim Amount.',
    dataIndex: 'oemUnCheckedClaimAmount',
    width: 270,
    customHeaderCell: (column: any) => distinctColor(column, 'distinct-unchecked')
  },
  {
    title: '1st Round Pass Rate',
    dataIndex: 'firstRoundPassRate',
    width: 200
  },
  { title: 'Final Pass Rate', dataIndex: 'finalPassRate', width: 250 },
  {
    title: 'Dealer Total Confirmed Rate',
    dataIndex: 'dealerTotalConfirmedRate',
    width: 200
  },
  {
    title: 'Dealer Total Rejected Rate',
    dataIndex: 'dealerTotalRejectedRate',
    width: 200
  },
  {
    title: 'Dealer Total Appeal Rate',
    dataIndex: 'dealerTotalAppealRate',
    width: 200
  },
  {
    title: 'Dealer Total Un-confirmed Rate',
    dataIndex: 'dealerTotalUnConfirmedRate',
    width: 220
  }
];
const programCol = [
  { title: 'Incentive Category', dataIndex: 'incentiveCategory', width: 130, fixed: 'left' },
  { title: 'Program Code', dataIndex: 'programCode', width: 140, fixed: 'left' },
  {
    title: 'Program Category',
    dataIndex: 'programCategoryNameEn',
    width: 140
  },
  { title: 'Program Name', dataIndex: 'programName', width: 380 },
  {
    title: 'Program Period',
    dataIndex: 'programPeriod',
    width: 200,
    slots: { customRender: 'programPeriod' }
  },
  {
    title: 'Estimated Vehicle No.',
    dataIndex: 'estimatedVehicleNo',
    width: 200
  },
  {
    title: 'Estimated Dealer No.',
    dataIndex: 'estimatedDealerNo',
    width: 200
  },
  {
    title: 'Program Checking Status',
    dataIndex: 'programCheckingStatus',
    width: 200
  },
  {
    title: 'Dealer Submission',
    children: [
      { title: 'Claim No.', dataIndex: 'claimNo', width: 200 },
      { title: 'Dealer No.', dataIndex: 'dealerNo', width: 200 },
      {
        title: 'Estimated Claim Amount',
        dataIndex: 'estimatedClaimAmount',
        width: 200
      },
      { title: 'Un-claimed No.', dataIndex: 'unClaimedNo', width: 200 },
      {
        title: 'Submission Rate',
        dataIndex: 'submisstionRateText',
        width: 200
      }
    ]
  },
  {
    title: 'Claim Checking',
    children: [
      {
        title: 'Checking Fulfillment',
        dataIndex: 'assignee',
        width: 250,
        slots: { customRender: 'checkingFulfillment' } //TODO
      },
      {
        title: 'Approved Claim No.',
        dataIndex: 'approvedClaimNo',
        width: 200
      },
      {
        title: 'Rejected Claim No.',
        dataIndex: 'rejectedClaimNo',
        width: 200
      },
      {
        title: 'Pending Claim No.',
        dataIndex: 'oemPendingClaimNo',
        width: 200
      },
      {
        title: 'Un-Checked Claim No.',
        dataIndex: 'oemUnCheckedClaimNo',
        width: 200
      },
      {
        title: 'Dealer Appealed Claim',
        dataIndex: 'dealerAppealedClaim',
        width: 200
      },
      {
        title: 'Dealer Appealed Claim Amount',
        dataIndex: 'dealerAppealedClaimAmount',
        width: 220
      },
      {
        title: 'Final Pass Rate',
        dataIndex: 'finalPassRate',
        width: 200,
        slots: { customRender: 'finalPassRate' }
      },
      {
        title: 'Claim Fail Rate',
        dataIndex: 'claimFailedRate',
        width: 200
      },
      {
        title: 'Program Completion Rate',
        dataIndex: 'programPenetrationRatio', //TODO
        width: 200
      }
    ]
  }
  // { title: "Operation", dataIndex: "checkResult", width: 200, slots: { customRender: "operation" } },
];
interface TreeDataItem {
  value: string;
  key: string;
  title?: string;
  disabled?: boolean;
  children?: TreeDataItem[];
}
const qmOptions: TreeDataItem[] = [
  {
    title: 'Q1',
    value: 'Q1',
    key: 'Q1',
    children: [
      {
        title: '01',
        value: '01',
        key: '01'
      },
      {
        title: '02',
        value: '02',
        key: '02'
      },
      {
        title: '03',
        value: '03',
        key: '03'
      }
    ]
  },
  {
    title: 'Q2',
    value: 'Q2',
    key: 'Q2',
    children: [
      {
        title: '04',
        value: '04',
        key: '04'
      },
      {
        title: '05',
        value: '05',
        key: '05'
      },
      {
        title: '06',
        value: '06',
        key: '06'
      }
    ]
  },
  {
    title: 'Q3',
    value: 'Q3',
    key: 'Q3',
    children: [
      {
        title: '07',
        value: '07',
        key: '07'
      },
      {
        title: '08',
        value: '08',
        key: '08'
      },
      {
        title: '09',
        value: '09',
        key: '09'
      }
    ]
  },
  {
    title: 'Q4',
    value: 'Q4',
    key: 'Q4',
    children: [
      {
        title: '10',
        value: '10',
        key: '10'
      },
      {
        title: '11',
        value: '11',
        key: '11'
      },
      {
        title: '12',
        value: '12',
        key: '12'
      }
    ]
  }
];
interface TypeClassItem {
  typeClass: string;
}
type Key = ColumnProps['key'];
export default defineComponent({
  components: {},
  setup() {
    //2022-01-07新需求，添加brand作为隐性筛选条件，是从首页跳转进入时生效。当点击reset按钮时，清空。
    let brand = '';
    const spaceSize = 6;
    const { state } = useStore();
    const searchCon = ref();
    const tableHeight = ref();
    const tableHeight2 = ref();
    const getMonth = (quarter: number) => {
      let array: Array<string> = [];
      switch (quarter) {
        case 1:
          array = ['Q1'];
          break;
        case 2:
          array = ['Q2'];
          break;
        case 3:
          array = ['Q3'];
          break;
        case 4:
          array = ['Q4'];
          break;
      }
      return array;
    };
    const dateArray = getMonth(moment().quarter());
    //显示的表格数据
    const dealerListData = ref<any[]>([]);
    const programListData = ref<any[]>([]);
    const parameterList = ref<any>({
      bu: null,
      region: null,
      subRegion: null,
      make: null,
      typeClass: null,
      // modelYear: [],
      modelYear: null,
      claimPeriodYear: '',
      claimPeriodQM: null,
      programPeriodYear: '',
      programPeriodQM: null,
      checkingPeriod: null,
      dealerCode: null,
      dealerName: null,
      dealerGroup: null
    });
    const route = useRoute();
    //period初始化
    const claimPeriodYear = ref<string>(moment().format('YYYY'));
    const openClaimPeriodYear = ref<boolean>(false);
    const claimPeriodDis = ref<boolean>(false);
    const claimPeriodQM = ref<string[]>(dateArray);
    const handlerOpenClaimPeriodChange = (status: any): void => {
      openClaimPeriodYear.value = status;
    };
    const handlerPanelClaimPeriodChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      claimPeriodYear.value = time as string;
      openClaimPeriodYear.value = false;
      if (time) {
        claimPeriodDis.value = false;
      } else {
        claimPeriodDis.value = true;
        claimPeriodQM.value = [];
      }
    };
    const claimPeriodChange = (value: string) => {
      if (value) {
        claimPeriodDis.value = false;
      } else {
        claimPeriodDis.value = true;
        claimPeriodQM.value = [];
      }
    };

    const programPeriodYear = ref<string>(moment().format('YYYY'));
    const openProgramPeriodYear = ref<boolean>(false);
    const programPeriodDis = ref<boolean>(false);
    const programPeriodQM = ref<string[]>(dateArray);
    const handlerOpenProgramPeriodChange = (status: any): void => {
      openProgramPeriodYear.value = status;
    };
    const handlerPanelProgramPeriodChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      programPeriodYear.value = time as string;
      openProgramPeriodYear.value = false;
      if (time) {
        programPeriodDis.value = false;
      } else {
        programPeriodDis.value = true;
        programPeriodQM.value = [];
      }
    };
    const programPeriodChange = (value: string) => {
      if (value) {
        programPeriodDis.value = false;
      } else {
        programPeriodDis.value = true;
        programPeriodQM.value = [];
      }
    };
    const checkingPeriodYear = ref<string>(moment().format('YYYY'));
    const checkingPeriodQM = ref<string[]>(dateArray);
    const openCheckingPeriodYear = ref<boolean>(false);
    const handlerOpenCheckingPeriodChange = (status: any): void => {
      openCheckingPeriodYear.value = status;
    };
    const handlerPanelCheckingPeriodChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      checkingPeriodYear.value = time as string;
      openCheckingPeriodYear.value = false;
    };
    watch(checkingPeriodYear, (newVal) => {
      if (!newVal) {
        checkingPeriodQM.value = [];
      }
    });
    //获取modleYear选项
    const modelYearLst = ref<any>([]);
    const dogetModleYear = () => {
      getModelYear().then((res) => {
        const dataArr = ref<any>([]);
        res.forEach((i: any, ind: any) => {
          if (i !== null) {
            dataArr.value.push(i);
          }
        });
        modelYearLst.value = dataArr.value;
      });
    };
    dogetModleYear();
    //make选项
    const makeOptions = ref<any>([]);
    //获取bu选项,region选项
    const businessUnitSelect = ref<any>([]);
    const buList = state.user.buList;
    const buNameenList = ref<any>([]);
    const regionLst = ref<any>('');
    const regiondis = ref<any>(false);
    const subregionLst = ref<any>('');
    const subregiondis = ref<any>(true);
    const nowbuId = ref<any>('');
    const dogetRegion = (e: any) => {
      regionLst.value = [];
      if (parameterList.value.bu) {
        regiondis.value = false;
      } else {
        parameterList.value.region = null;
        regiondis.value = true;
        parameterList.value.subRegion = null;
        subregiondis.value = true;
      }
      state.user.buList.forEach((bui: any, buind: any) => {
        if (bui.nameEn === parameterList.value.bu) {
          nowbuId.value = bui.id;
          getRegion({ buId: `${bui.id}` }).then((res) => {
            parameterList.value.region = null;
            const sortArr: any[] = [[], [], [], [], []];
            const maps = {
              n: 0,
              e: 1,
              s: 3,
              w: 2
            };
            res.forEach((region: any) => {
              const firstLetter = (region.regionEn[0] as string).toLocaleLowerCase();
              if (firstLetter in maps) {
                sortArr[maps[firstLetter]].push(region);
              } else {
                sortArr[4].push(region);
              }
            });
            regionLst.value = sortArr.flat();
          });
        }
      });
    };
    const dogetSubregion = (e: any) => {
      if (e) {
        subregiondis.value = false;
      } else {
        parameterList.value.subRegion = null;
        subregiondis.value = true;
      }

      regionLst.value.forEach((rei: any, reind: any) => {
        if (rei.regionEn === parameterList.value.region) {
          getRegion({
            buId: `${nowbuId.value}`,
            regionId: `${rei.id}`
          }).then((res) => {
            parameterList.value.subRegion = null;
            subregionLst.value = res;
          });
        }
      });
    };
    const dogetMake = (e: any) => {
      makeOptions.value = [];
      const makeLst = ref<any>([]);
      if (e.length > 0) {
        Array.from(e).forEach((i: any, ind: any) => {
          switch (i) {
            case 'MBPC':
              makeLst.value = makeLst.value.concat([{ make: 'CBU' }, { make: 'PBP' }]);
              break;
            case 'VAN':
              makeLst.value = makeLst.value.concat([{ make: 'VAN' }]);
              break;
            case 'DENZA':
              makeLst.value = makeLst.value.concat([{ make: 'DENZA' }]);
              break;
            default:
              makeLst.value = makeLst.value.concat([]);
              break;
          }
        });
        makeOptions.value = makeLst.value;
      } else {
        makeOptions.value = [];
      }
    };
    const dealerNameLst = ref<any>([]);
    const dealerCodeLst = ref<any>([]);
    const dogetDealer = (e: any) => {
      let nbuid = '';
      state.user.buList.forEach((bui: any, buind: any) => {
        if (bui.nameEn === e) {
          nbuid = bui.id;
        }
      });
      getDealerByBUID({
        buId: nbuid
      }).then((res) => {
        res.forEach((deai: any, deaind: any) => {
          dealerNameLst.value.push(deai.dealerInfo.dealerNameEn);
          dealerCodeLst.value.push(deai.dealerInfo.dealerCode);
        });
      });
    };
    //Checking Period选项
    const checkingRoundList = ref<any[]>([]);
    const showCheckingRound = (e: any) => {
      const buidLst = ref<any>([]);
      state.user.buList.forEach((bui: any, buind: any) => {
        e.forEach((ei: any, eind: any) => {
          if (bui.nameEn === ei) {
            buidLst.value.push(bui.id);
          }
        });
      });
      getCheckingRoundList({ buLst: buidLst.value }).then((res) => {
        checkingRoundList.value = res;
      });
    };
    const doGetBuList = () => {
      // businessUnitSelect.value = state.user.buList;
      businessUnitSelect.value = state.user.buList.filter((bu: any) => {
        return bu.parentId === null;
      });
      const bulst = ref<any>([]);
      const hasmb = ref<any>(false);
      buList.forEach((i: any, ind: any) => {
        let isrepeat = false;
        bulst.value.forEach((bui: any, buind: any) => {
          if (i.nameEn === bui) {
            isrepeat = true;
          }
        });
        if (!isrepeat) {
          bulst.value.push(i.nameEn);
        }
        if (i.nameEn === 'MBPC') {
          hasmb.value = true;
        }
      });
      buNameenList.value = bulst.value;
      if (route.query.bu) {
        state.user.buList.forEach((bui: any, buind: any) => {
          if (route.query.bu === bui.id + '') {
            parameterList.value.bu = bui.nameEn;
            console.log(837, parameterList.value.bu);
          }
        });
        brand = brand === '' ? '' : route.query.brand === 'All' ? '' : (route.query.brand as string);
        //从首页报表，跳转进入时有些值需要重新赋予，有些需要置空
        if (route.query.isClear === 'true') {
          programPeriodYear.value = (route.query.year as string) || '';
          programPeriodQM.value = [];
          claimPeriodYear.value = '';
          claimPeriodQM.value = [];
          checkingPeriodYear.value = '';
          checkingPeriodQM.value = [];
          if (route.query.quarter) {
            programPeriodQM.value = (route.query.quarter as string).split(',');
          }
        }
      } else {
        if (businessUnitSelect.value.length === 0) return;
        if (hasmb.value) {
          parameterList.value.bu = 'MBPC';
        } else {
          parameterList.value.bu = businessUnitSelect.value[0].nameEn;
        }
      }
      dogetRegion([parameterList.value.bu]);
      dogetMake([parameterList.value.bu]);
      showCheckingRound([parameterList.value.bu]);
      dogetDealer(parameterList.value.bu);
      // console.log(693,state.user.buList)
    };
    //查询type class options
    const { data: typeClassOptions } = useFetch<TypeClassItem[]>({}, [], getTypeClass);
    // 获取dealergroup
    const dealerGroupList = ref([]);
    const genDealerGroup = () => {
      getDealerGroup().then((res: any) => {
        dealerGroupList.value = res;
      });
    };
    genDealerGroup();

    //重置
    const handlerReset = () => {
      brand = '';
      parameterList.value.brand = '';
      parameterList.value.bu = null;
      parameterList.value.region = null;
      parameterList.value.subRegion = null;
      parameterList.value.make = null;
      parameterList.value.typeClass = null;
      parameterList.value.modelYear = null;
      // parameterList.value.modelYear = [];
      parameterList.value.claimPeriodYear = '';
      claimPeriodQM.value = dateArray;
      parameterList.value.programPeriodYear = '';
      programPeriodQM.value = dateArray;
      parameterList.value.checkingPeriodYear = '';
      checkingPeriodQM.value = dateArray;
      parameterList.value.dealerCode = '';
      parameterList.value.dealerName = '';
      parameterList.value.dealerGroup = null;
      claimPeriodYear.value = moment().format('YYYY');
      programPeriodYear.value = moment().format('YYYY');
      checkingPeriodYear.value = moment().format('YYYY');
      programPeriodDis.value = false;
      claimPeriodDis.value = false;
      doGetBuList();
    };

    const genQuarter = (year: string, param: Array<string> | string | undefined) => {
      const maps = {
        Q1: '1',
        Q2: '2',
        Q3: '3',
        Q4: '4'
      };
      let quaList: string[] = [];
      for (const qm of param as string[]) {
        if (qm in maps) {
          quaList = [...quaList, ...maps[qm]];
        }
      }
      const quarterList = quaList.map((qua: any) => {
        return `${year}-${qua}`;
      });
      return quarterList.length > 0 ? quarterList : undefined;
    };

    const genMonth = (year: string, param: Array<string>) => {
      const maps = {
        Q1: ['1-01', '1-02', '1-03'],
        Q2: ['2-04', '2-05', '2-06'],
        Q3: ['3-07', '3-08', '3-09'],
        Q4: ['4-10', '4-11', '4-12'],
        '01': ['1-01'],
        '02': ['1-02'],
        '03': ['1-03'],
        '04': ['2-04'],
        '05': ['2-05'],
        '06': ['2-06'],
        '07': ['3-07'],
        '08': ['3-08'],
        '09': ['3-09'],
        '10': ['4-10'],
        '11': ['4-11'],
        '12': ['4-12']
      };
      let mthList: string[] = [];
      for (const qm of param as string[]) {
        mthList = [...mthList, ...maps[qm]];
      }
      const monthList = mthList.map((mth: any) => {
        return `${year}-${mth}`;
      });
      return monthList.length > 0 ? monthList : undefined;
    };

    //整理获取parameter
    const parameterDataLst = ref<any>({
      brand: '', //新需求，2022-01-07
      bus: '',
      region: '',
      subRegion: '',
      make: '',
      typeClass: '',
      // modelYear: [],
      modelYear: '',
      dealerCode: '',
      dealerName: '',
      dealerGroup: '',
      claimPeriodYear: '',
      claimPeriodQuarter: [],
      claimPeriodMonth: [],
      programPeriodYear: '',
      programPeriodQuarter: [],
      programPeriodMonth: [],
      roundStartPeriodYear: '',
      // checkingPeriodQuarter: [],
      roundStartPeriodMonth: [],
      checkingPeriodId: []
    });
    const dogetParameter = () => {
      parameterDataLst.value.brand = brand;
      parameterDataLst.value.size = -1;
      parameterDataLst.value.current = 0;
      parameterDataLst.value.make = parameterList.value.make;
      parameterDataLst.value.typeClass = parameterList.value.typeClass;
      parameterDataLst.value.modelYear = parameterList.value.modelYear;
      parameterDataLst.value.dealerCode = parameterList.value.dealerCode;
      parameterDataLst.value.dealerName = parameterList.value.dealerName;
      parameterDataLst.value.dealerGroup = parameterList.value.dealerGroup;
      if (parameterList.value.bu) {
        parameterDataLst.value.bus = [parameterList.value.bu];
        if (parameterList.value.region) {
          parameterDataLst.value.region = parameterList.value.region;
          if (parameterList.value.subRegion) {
            parameterDataLst.value.subRegion = parameterList.value.subRegion;
          }
        }
      } else {
        parameterDataLst.value.bus = [buNameenList.value];
      }

      if (claimPeriodQM.value.length > 0) {
        parameterDataLst.value.claimPeriodQuarter = genQuarter(claimPeriodYear.value, claimPeriodQM.value);
        parameterDataLst.value.claimPeriodMonth = genMonth(claimPeriodYear.value, claimPeriodQM.value);
      } else {
        parameterDataLst.value.claimPeriodQuarter = [];
        parameterDataLst.value.claimPeriodMonth = [];
      }
      parameterDataLst.value.claimPeriodYear = claimPeriodYear.value ? claimPeriodYear.value : '';

      if (programPeriodQM.value.length > 0) {
        parameterDataLst.value.programPeriodQuarter = genQuarter(programPeriodYear.value, programPeriodQM.value);
        parameterDataLst.value.programPeriodMonth = genMonth(programPeriodYear.value, programPeriodQM.value);
      } else {
        parameterDataLst.value.programPeriodQuarter = [];
        parameterDataLst.value.programPeriodMonth = [];
      }
      parameterDataLst.value.programPeriodYear = programPeriodYear.value ? programPeriodYear.value : '';

      if (checkingPeriodQM.value.length > 0) {
        // parameterDataLst.value.checkingPeriodQuarter = genQuarter(
        //     checkingPeriodYear.value,
        //     checkingPeriodQM.value
        // );
        parameterDataLst.value.roundStartPeriodMonth = genMonth(checkingPeriodYear.value, checkingPeriodQM.value);
      } else {
        // parameterDataLst.value.checkingPeriodQuarter = [];
        parameterDataLst.value.roundStartPeriodMonth = [];
      }
      parameterDataLst.value.roundStartPeriodYear = checkingPeriodYear.value ? checkingPeriodYear.value : '';
    };
    nextTick(() => {
      (document.getElementsByClassName('table-box')[0] as any).style.height =
        window.innerHeight - 180 - searchCon.value.$el.scrollHeight + 'px';
      const height = (document.getElementsByClassName('table-box')[0] as any).style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf('px')));
      const paginationHeight = 52;
      tableHeight.value = heightNum - 150 - paginationHeight;
      tableHeight2.value = heightNum - 150 - paginationHeight;
    });
    const doGetDealerData = () => {
      dogetParameter();
      const params = {
        ...parameterDataLst.value,
        current: dealerPagination.current - 1,
        size: dealerPagination.pageSize
      };
      getComDealerList(params).then((res) => {
        dealerListData.value = res.data;
        dealerPagination.pageSize = res.pageSize;
        dealerPagination.current = res.page + 1;
        dealerPagination.total = res.totalNum;
      });
    };
    const doGetProgramData = () => {
      dogetParameter();
      const params = {
        ...parameterDataLst.value,
        current: programPagination.current - 1,
        size: programPagination.pageSize
      };
      getComProgramList(params).then((res) => {
        programListData.value = res.data;
        programListData.value.forEach((i: any, ind: any) => {
          if (i.finalPassRate.split('%').length > 1) {
            programListData.value[ind].finalPassRateNum = parseFloat(i.finalPassRate.split('%')[0]);
          }
        });
        programPagination.pageSize = res.pageSize;
        programPagination.current = res.page + 1;
        programPagination.total = res.totalNum;
      });
    };
    const dogetAllData = () => {
      if (!programPeriodYear.value) {
        message.error('Program Period (Year) and BU must be selected!');
        return;
      }
      doGetDealerData();
      doGetProgramData();
    };
    const handlerSearch = () => {
      dogetParameter();
      // doGetDealerData()
      // doGetProgramData()
    };
    //切换tab
    const tabN = ref<any>('Dealer Based');
    const tabChange = (e: any) => {
      if (e === '1') {
        tabN.value = 'Dealer Based';
      } else if (e === '2') {
        tabN.value = 'Program Based';
      }
    };
    //下载
    const downloadTableData = [
      {
        file: 'Raw Data',
        fileType: 'raw'
      },
      {
        file: 'Report Data',
        fileType: 'report'
      }
    ];
    const downloadRowSelection = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: any[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: any[]) => {
        downloadRowSelection.selectedRowKeys = selectedRowKeys;
        downloadRowSelection.selectedRowData = selectedRows;
      },
      selectedRowData: [],
      selectedRowKeys: []
    });
    const downloadColumnsData = [{ title: 'File', dataIndex: 'file', align: 'left' }];
    const downloadVisible = ref(false);
    const openDownload = () => {
      downloadVisible.value = true;
    };
    const closeDownload = () => {
      downloadVisible.value = false;
      downloadRowSelection.selectedRowKeys.length = 0;
    };
    const handlerDownload = (type: any) => {
      if (downloadRowSelection.selectedRowKeys.length < 1) {
        message.warning('请先选择数据');
        return;
      } else {
        dogetParameter();
        let type = '';
        if (downloadRowSelection.selectedRowKeys.length === 2) {
          type = 'all';
        } else {
          type = downloadRowSelection.selectedRowKeys[0] as string;
        }
        if (type === 'all') {
          parameterDataLst.value.tabName = `${tabN.value}&Raw Data`;
        } else if (type === 'report') {
          parameterDataLst.value.tabName = tabN.value;
        } else if (type === 'raw') {
          parameterDataLst.value.tabName = `Raw Data`;
        }
        const paramsConfig = {
          url: `/rpapi/dealer/communication/analysis/report/export`,
          method: 'post',
          params: parameterDataLst.value
        };
        downloadFile(paramsConfig, 'application/vnd-excel; char-set=UTF-8');
        closeDownload();
      }
    };

    const myChart = ref<any>();
    const chartRef = ref<HTMLElement>();
    const queryChartData = () => {
      //
      dogetParameter();
    };

    const chartVisible = ref<any>(false);
    const openChart = () => {
      chartVisible.value = true;
      dogetParameter();
      const chartParams = {
        current: 0,
        size: -1,
        bus: parameterDataLst.value.bus,
        claimPeriodQuarter: parameterDataLst.value.claimPeriodQuarter,
        claimPeriodMonth: parameterDataLst.value.claimPeriodMonth,
        checkingPeriodId: parameterDataLst.value.checkingPeriodId,
        tabName: tabN.value,
        region: parameterDataLst.value.region,
        subRegion: parameterDataLst.value.subRegion,
        make: parameterDataLst.value.make,
        typeClass: parameterDataLst.value.typeClass,
        modelYear: parameterDataLst.value.modelYear,
        dealerCode: parameterDataLst.value.dealerCode,
        dealerName: parameterDataLst.value.dealerName,
        dealerGroup: parameterDataLst.value.dealerGroup,
        claimPeriodYear: parameterDataLst.value.claimPeriodYear,
        programPeriodQuarter: parameterDataLst.value.programPeriodQuarter,
        programPeriodMonth: parameterDataLst.value.programPeriodMonth,
        programPeriodYear: parameterDataLst.value.programPeriodYear,
        roundStartPeriodYear: parameterDataLst.value.roundStartPeriodYear,
        roundStartPeriodMonth: parameterDataLst.value.roundStartPeriodMonth
      };
      const brandName = brand === '' ? [] : [brand];
      const unwarp = (obj: any) => obj && (obj.__v_raw || obj.valueOf() || obj);
      window.setTimeout(() => {
        if (chartRef.value) {
          myChart.value = echarts.init(chartRef.value as HTMLElement);
          getIncentiveAll({
            communicationReqVo: chartParams,
            brandName
          }).then((res) => {
            unwarp(myChart.value).setOption(res.option);
          });
        }
      }, 300);
    };
    const closeChart = () => {
      chartVisible.value = false;
    };

    const routeInit = () => {
      const query = route.query;
      if (Object.keys(query).length > 0) {
        parameterList.value.bu = businessUnitSelect.value.find((bu: any) => {
          return `${bu.id}` === (route.query.bu as string);
        }).nameEn;
        // parameterList.value.brand = route.query.brand as string;
        // programPeriodYear.value = route.query.year as string;
        // if (route.query.quarter) {
        //     programPeriodQM.value = Array.from(route.query.quarter).map(
        //         (q) => {
        //             return `Q${q as string}`;
        //         }
        //     );
        // }
      }
    };

    // 分页
    const dealerPagination = reactive<PageOptionProps>({
      pageSize: 10,
      current: 1,
      total: 1
    });

    const handleDealerPageChange = (page: number, pageSize: number) => {
      dealerPagination.current = page;
      dogetAllData();
    };
    const handleDealerSizeChange = (page: number, pageSize: number) => {
      dealerPagination.pageSize = pageSize;
      dogetAllData();
    };

    const programPagination = reactive<PageOptionProps>({
      pageSize: 10,
      current: 1,
      total: 1
    });

    const handleProgramPageChange = (page: number, pageSize: number) => {
      programPagination.current = page;
      dogetAllData();
    };
    const handleProgramSizeChange = (page: number, pageSize: number) => {
      programPagination.pageSize = pageSize;
      dogetAllData();
    };

    onMounted(() => {
      doGetBuList();
      dogetAllData();
      nextTick(() => {
        routeInit();
      });
    });

    return {
      dealerPagination,
      handleDealerPageChange,
      handleDealerSizeChange,
      programPagination,
      handleProgramPageChange,
      handleProgramSizeChange,
      spaceSize,
      dealerNameLst,
      dealerCodeLst,
      closeChart,
      openChart,
      chartVisible,
      queryChartData,
      chartRef,
      myChart,
      closeDownload,
      openDownload,
      downloadVisible,
      downloadColumnsData,
      downloadRowSelection,
      downloadTableData,
      nowbuId,
      tabN,
      tabChange,
      genDealerGroup,
      dealerGroupList,
      typeClassOptions,
      showCheckingRound,
      checkingRoundList,
      handlerDownload,
      dogetAllData,
      dogetMake,
      makeOptions,
      buNameenList,
      handlerSearch,
      dogetParameter,
      programPeriodChange,
      programPeriodDis,
      claimPeriodChange,
      claimPeriodDis,
      dogetSubregion,
      subregiondis,
      subregionLst,
      buList,
      handlerReset,
      dogetRegion,
      regionLst,
      regiondis,
      doGetBuList,
      businessUnitSelect,
      dogetModleYear,
      modelYearLst,
      handlerPanelCheckingPeriodChange,
      handlerOpenCheckingPeriodChange,
      openCheckingPeriodYear,
      checkingPeriodYear,
      checkingPeriodQM,
      handlerPanelProgramPeriodChange,
      handlerOpenProgramPeriodChange,
      openProgramPeriodYear,
      programPeriodYear,
      programPeriodQM,
      claimPeriodQM,
      qmOptions,
      handlerPanelClaimPeriodChange,
      handlerOpenClaimPeriodChange,
      openClaimPeriodYear,
      claimPeriodYear,
      parameterList,
      doGetProgramData,
      doGetDealerData,
      searchCon,
      tableWidth,
      tableHeight,
      tableHeight2,
      dealerCol,
      dealerListData,
      programListData,
      programCol,
      SHOW_PARENT
    };
  }
});
